import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../config/theme";
import realSign from "images/br.png";
import logo from "images/cap_logo.png";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import { numberAsCurrency } from './utils/index';
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";
import StarIcon from "@material-ui/icons/Star";
import MoneyIcon from "@material-ui/icons/Money";
import AwesomeIcon from "@material-ui/icons/FlashOn";
import CampaignDetails from './pages/Campaign/CampaignDetails'
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Home from "./pages/Home/Home.js";
import Termos from "./pages/Termos.js";
import Faq from "./pages/Faq.js";
import SmsConfirmation from "./pages/SmsConfirmation.js";
import WinnersIndex from "./pages/WinnersIndex.js";
import DocumentConfirmation from "./pages/DocumentConfirmation.js";
import EditProfile from "./pages/EditProfile.js";
import ChangeEmail from "./pages/ChangeEmail.js";
import ChangePhone from "./pages/ChangePhone.js";
import ChangeSocialName from "./pages/ChangeSocialName.js";
import Withdrawals from "./pages/Withdrawals.js";
import Withdrawal from "./pages/Withdrawal.js";
import ChangePassword from "./pages/ChangePassword.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountRemove from "./pages/AccountRemove.js";
import axios from "axios";
import Container from "@material-ui/core/Container";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "../public/styles/main.scss";
import SendConfirmationInstructions from "./components/SendConfirmationInstructions";
import Box from '@material-ui/core/Box';
import Footer from '../components/Footer.js'
import MyOrders from "./pages/MyOrders.js";
import CampaignsIndex from "./pages/CampaignsIndex.js";
import OrderPage from "./pages/Order/OrderPage.js";
import Cookie from "js-cookie";
import Politicas from './pages/Politicas.js';

export const UserContext = React.createContext(null);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: navigator.userAgent == "app-ios" ? "-50px" : ""
  },
  appBarShift: {
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    backgroundColor: "rgb(9, 18, 80)",
    background:
      "linear-gradient(0deg, rgba(9, 18, 80, 1) 0%, rgba(15, 24, 150, 1) 100%)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    color: "#fff",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    maxWidth: "-webkit-fill-available",
    textAlign: "-webkit-center",
    '@supports ( -moz-appearance:none )': {
      maxWidth: "-moz-available",
      textAlign: "-moz-center",
    },
    '@media(max-width: 1920px)': {
      minHeight: '600px',
    },
    '@media(max-width: 550px)': {
      minHeight: '825px',
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  icons: {
    fill: "#58DF46",
  },
  menuIcons: {
    fill: "#fff",
  },
  signOut: {
    textDecoration: 'none',
    marginLeft: '10px',
    "@media(max-width: 375px)": {
      marginLeft: '2px',
    },
  },
  user: {
    backgroundColor: "transparent",
    width: "33px",
    minWidth: "33px",
    marginLeft: "10px",
    "&:hover": { backgroundColor: "#2AB4C6" },
  },
  userBalance: {
    display: "flex",
    alignItems: "center",
  },
  menuItemUser: {
    justifyContent: "space-around",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuWithdrawal: {
    backgroundColor: "rgb(102, 0, 203)",
    background:
      "linear-gradient(90deg, rgba(102,0,203,1) 0%, rgba(8,12,75,1) 100%)",
    width: "15%",
    textAlign: "center",
    justifyContent: "center",
    "@media(max-width: 1352px)": {
      minWidth: "20%",
      maxWidth: "25%",
    },
    "@media(max-width: 768px)": {
      minWidth: "35%",
      maxWidth: "50%",
    },
    "@media(max-width: 500px)": {
      minWidth: "65%",
      maxWidth: "80%",
    },
  },
  withdrawal: {
    backgroundColor: "#58DF46",
    borderRadius: "7px",
  },
  navLink: {
    textDecoration: "none",
  },
  navLinkSignOut: {
    display: "flex",
    textDecoration: "none",
  },
  marcaDiv: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: 10,
    justifyContent: 'flex-end',
    '@media(max-width: 600px)': {
      marginTop: 0,
    }
  },
  marcaA: {
    textDecoration: 'none',
    '@media(min-width: 1024px)': {
      // marginLeft: 150,
    }
  },
  marcaImg: {
    '@media(max-width: 600px)': {
      paddingTop: 10,
    }
  },
  realSign: {
    verticalAlign: "text-top",
    margin: '5px 5px 0px 20px',
    '@media(max-width: 475px)': {
      margin: '6px 1px',
    }
  },
  menuBalance: {
    '@media(max-width: 475px)': {
      // display: 'none'
      fontSize: '0.8rem',
      paddingTop: '3px'
    }
  },
  userDollar: {
    cursor: 'pointer',
    display: 'flex',
    '@media(max-width: 475px)': {
      '& > button': {
        marginLeft: '2px'
      }
    }
  },
  footer: {
    width: '100%',
    position: 'relative',
    bottom: 0,
    backgroundColor: 'rgb(14, 5, 82)',
  }
}));

export default function InnerApp({
  flash,
  global_schema,
  influencer,
  current_customer
}) {
  // , withdrawal_minimum_value
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(current_customer);
  const userReady = currentUser && currentUser.cpf == true;
  const history = useHistory();
  console.log(history);
  const needsCpfValidation = currentUser && currentUser.cpf == false;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const [minimumValue, setMinimumValue] = useState(withdrawal_minimum_value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [visitantAnchorEl, setVisitantAnchorEl] = React.useState(null);
  const query = new URLSearchParams(window.location.search);

  // const [drawerOpen, setDrawerOpen] = useState({ left: false });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleVisitantClick = (event) => {
    setVisitantAnchorEl(event.currentTarget);
  };

  function handleLoginClick() {
    Cookie.set('redirect_to', window.location.href, { path: '/', expires: 1 });
    window.location.href = "/public/sign_in_or_up";
  }

  const handleClose = () => {
    setAnchorEl(null);
    setVisitantAnchorEl(null);
  };

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function signOut(e) {
    e.preventDefault();
    axios
      .delete("/customers/sign_out.json")
      .then(function (res) {
        window.location.href = `/${influencer?.username}/site`;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (userReady && Cookie.get('redirect_to')) {
      var redirect_to = Cookie.get('redirect_to')
      Cookie.remove('redirect_to');
      window.location.href = redirect_to;
      return;
    }

    // Atualiza o titulo do documento usando a API do browser
    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  function handleOrdersClick(e) {
    e.preventDefault();
    if (userReady) {
      history.push(`/${influencer?.username}/site/compras`);
      setOpen(false);
    } else {
      Cookie.set('redirect_to', `/${influencer?.username}/site/compras`, { path: '/', expires: 1 });
      window.location.href = "/public/sign_in";
    }
  }

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div>
      <div className={classes.root}>
        <UserContext.Provider value={{ currentUser: currentUser, setCurrentUser: setCurrentUser }}>
          <CssBaseline />
          {navigator.userAgent != "app-ios" ? (
            <AppBar>
              <Toolbar style={{ justifyContent: "space-between", color: "#fff" }}>
                <IconButton
                  color="inherit"
                  aria-label="open"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  className={classes.marcaDiv}
                >
                  <a href={`/${influencer?.username}`} className={classes.marcaA}>
                    <img
                      src="https://d2zdlj1ifv8u1d.cloudfront.net/images/cap_logo.png"
                      alt=""
                      title={"icone"}
                      height="50"
                      className={classes.marcaImg}
                    />
                  </a>
                </Typography>
                <div className={classes.userBalance}>
                  {userReady ? (
                    <>
                      <div className={classes.userBalance}>
                        <div
                          className={classes.userDollar}
                          aria-controls="user-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          {currentUser.balance && currentUser.balance > 0 &&
                            <>
                              <img
                                src={realSign}
                                className={classes.realSign}
                                alt=""
                                title={"icone"}
                                width="25"
                                height="25"
                              />
                              <Typography
                                className={classes.menuBalance}
                                style={{ marginTop: "6px" }}
                              >
                                {" "}
                                {numberAsCurrency(currentUser.balance, false)}
                              </Typography>
                            </>
                          }
                          <Button className={classes.user} color="inherit">
                            <PersonOutlineIcon className={classes.icons} />
                          </Button>
                        </div>
                      </div>
                    </>
                  ) :
                    (
                      needsCpfValidation ?
                        (
                          <div>
                            <NavLink className={classes.navLinkSignOut} to="#" onClick={signOut}>
                              <ExitToAppIcon className={classes.menuIcons} />
                              <Typography className={classes.signOut}>Sair</Typography>
                            </NavLink>
                          </div>
                        ) :
                        <>
                          <div className={classes.userBalance}>
                            <div
                              className={classes.userDollar}
                              aria-controls="visitant-menu"
                              aria-haspopup="true"
                              onClick={handleVisitantClick}
                            >
                              <Button className={classes.user} color="inherit">
                                <PersonOutlineIcon className={classes.icons} />
                              </Button>
                            </div>
                          </div>
                        </>
                    )}
                  <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    classes={{
                      paper: classes.menuWithdrawal,
                    }}
                  >
                    <NavLink
                      className={classes.navLink}
                      to="/site/change_profile"
                      onClick={() => setOpen(false)}
                    >
                      <MenuItem
                        className={classes.menuItemUser}
                        onClick={handleClose}
                      >
                        <PersonOutlineIcon className={classes.icons} />
                        <Typography
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          {currentUser?.best_name?.split(" ")[0]}
                        </Typography>
                      </MenuItem>
                    </NavLink>
                    {
                      currentUser && currentUser.balance && currentUser.balance > 0 &&
                      <>
                        <Divider />
                        <MenuItem
                          className={classes.menuItemUser}
                          onClick={handleClose}
                        >
                          <LocalAtmIcon className={classes.icons} />
                          Saldo Disponível
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItemUser}
                          onClick={handleClose}
                        >
                          <Typography>{numberAsCurrency(currentUser.balance) || "R$ 0,00"}</Typography>
                        </MenuItem>
                        <MenuItem className={classes.menuItemUser}>
                          <Button className={classes.withdrawal} color="inherit">
                            <NavLink
                              to="/site/withdrawal"
                              style={{ textDecoration: "none", color: '#fff' }}
                              onClick={handleClose}
                            >
                              SACAR
                            </NavLink>
                          </Button>
                        </MenuItem>
                      </>
                    }
                    <Divider />
                    <NavLink
                      className={classes.navLink}
                      to="#"
                      onClick={signOut}
                    >
                      <MenuItem
                        className={classes.menuItemUser}
                        onClick={handleClose}
                      >
                        <ExitToAppIcon className={classes.icons} />
                        <Typography
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Sair
                        </Typography>
                      </MenuItem>
                    </NavLink>
                  </Menu>
                  <Menu
                    id="visitant-menu"
                    anchorEl={visitantAnchorEl}
                    open={Boolean(visitantAnchorEl)}
                    onClose={handleClose}
                    classes={{
                      paper: classes.menuWithdrawal,
                    }}
                  >
                    <NavLink
                      className={classes.navLink}
                      to="#"
                      onClick={handleLoginClick}
                    >
                      <MenuItem
                        className={classes.menuItemUser}
                        onClick={handleClose}
                      >
                        <PersonOutlineIcon className={classes.icons} />
                        <Typography
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Entrar
                        </Typography>
                      </MenuItem>
                    </NavLink>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>)
            : ("")}
          {!currentUser || userReady ? (
            <SwipeableDrawer
              anchor="left"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                {currentUser && currentUser.email.length > 1 && !currentUser.confirmed_at && <SendConfirmationInstructions />}
                <Divider />
                <NavLink
                  className={classes.navLink}
                  to={`/${influencer?.username}/site`}
                  onClick={() => setOpen(false)}
                >
                  <ListItem button key="home">
                    <ListItemIcon>
                      <HomeIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Início" />
                  </ListItem>
                </NavLink>
                <NavLink
                  className={classes.navLink}
                  to={`/${influencer?.username}/site/campanhas`}
                  onClick={() => setOpen(false)}
                >
                  <ListItem button key="campanhas">
                    <ListItemIcon>
                      <AwesomeIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Campanhas" />
                  </ListItem>
                </NavLink>
                <NavLink
                  className={classes.navLink}
                  to={`/${influencer?.username}/site/ganhadores`}
                  onClick={() => setOpen(false)}
                >
                  <ListItem button key="ganhadores">
                    <ListItemIcon>
                      <StarIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Ganhadores" />
                  </ListItem>
                </NavLink>
                <NavLink
                  className={classes.navLink}
                  to="#"
                  onClick={handleOrdersClick}
                >
                  <ListItem button key="compras">
                    <ListItemIcon>
                      <MoneyIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Meus títulos" />
                  </ListItem>
                </NavLink>
                <Divider />
                {
                  userReady && (
                    <NavLink
                      className={classes.navLink}
                      to="/site/withdrawals"
                      onClick={() => setOpen(false)}
                    >
                      <ListItem button key="withdrawals">
                        <ListItemIcon>
                          <MonetizationOnIcon className={classes.menuIcons} />
                        </ListItemIcon>
                        <ListItemText primary="Meus Saques" />
                      </ListItem>
                    </NavLink>
                  )
                }
                <Divider />
                <NavLink
                  className={classes.navLink}
                  to="/site/politicas"
                  onClick={() => setOpen(false)}
                >
                  <ListItem button key="politicas">
                    <ListItemIcon>
                      <LibraryBooksIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Políticas" />
                  </ListItem>
                </NavLink>
                <NavLink
                  className={classes.navLink}
                  to="/site/termos"
                  onClick={() => setOpen(false)}
                >
                  <ListItem button key="termos">
                    <ListItemIcon>
                      <LibraryBooksIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Termos de Uso" />
                  </ListItem>
                </NavLink>
                <NavLink
                  className={classes.navLink}
                  to="/site/faq"
                  onClick={() => setOpen(false)}
                >
                  <ListItem button key="faq">
                    <ListItemIcon>
                      <HelpIcon className={classes.menuIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Dúvidas Frequentes" />
                  </ListItem>
                </NavLink>
                <Divider />
                {
                  userReady && (
                    <NavLink
                      className={classes.navLink}
                      to="/site/change_profile"
                      onClick={() => setOpen(false)}
                    >
                      <ListItem button key="change_profile">
                        <ListItemIcon>
                          <EditIcon className={classes.menuIcons} />
                        </ListItemIcon>
                        <ListItemText primary="Editar Perfil" />
                      </ListItem>
                    </NavLink>
                  )
                }
                <Divider />
                {
                  userReady && (
                    <NavLink className={classes.navLink} to="#" onClick={signOut}>
                      <ListItem button key="counter">
                        <ListItemIcon>
                          <ExitToAppIcon className={classes.menuIcons} />
                        </ListItemIcon>
                        <ListItemText primary="Sair" />
                      </ListItem>
                    </NavLink>
                  )
                }
              </List>
            </SwipeableDrawer>
          ) : (
            ""
          )}
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <ToastContainer autoClose={8000} />
            <div>
              {
                needsCpfValidation ?
                  <Container className={classes.paper} maxWidth="xs">
                    <Route exact path={["/:influencer_username", "/:influencer_username/site", "/site", "*"]}>
                      <DocumentConfirmation />
                    </Route>
                  </Container>
                  :
                  <Route exact path={["/:influencer_username", "/:influencer_username/site", "/site", "/"]}>
                    <Home currentUser={currentUser} influencer={influencer} isMobile={isMobile} />
                  </Route>
              }
              {
                userReady &&
                <Route exact path={["/:influencer_username/site/change_profile", "/site/change_profile"]}>
                  <EditProfile user={currentUser} />
                </Route>
              }
              {
                userReady &&
                <>
                  <Container maxWidth="xs">
                    <Route exact path={["/:influencer_username/site/change_email", "/site/change_email"]}>
                      <ChangeEmail user={currentUser} />
                    </Route>
                    <Route exact path={["/:influencer_username/site/change_phone", "/site/change_phone"]}>
                      <ChangePhone />
                    </Route>
                    <Route exact path={["/:influencer_username/site/change_password", "/site/change_password"]}>
                      <ChangePassword user={currentUser} />
                    </Route>
                    <Route exact path={["/:influencer_username/site/change_social_name", "/site/change_social_name"]}>
                      <ChangeSocialName user={currentUser} />
                    </Route>
                    <Route exact path={["/:influencer_username/site/withdrawal", "/site/withdrawal"]}>
                      <Withdrawal currentUser={currentUser} />
                    </Route>
                    <Route exact path={["/:influencer_username/site/account_remove", "/site/account_remove"]}>
                      <AccountRemove user={currentUser} />
                    </Route>
                  </Container>
                  <Route exact path={["/:influencer_username/site/withdrawals", "/site/withdrawals"]}>
                    <Withdrawals currentUser={currentUser} incluencer={influencer} />
                  </Route>
                </>
              }
              {
                !needsCpfValidation &&
                <>
                  <Route exact path={["/:influencer_username/site/campanhas/:campaignId", "/site/campanhas/:campaignId"]}>
                    <CampaignDetails currentUser={currentUser} influencer={influencer} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/ganhadores", "/site/ganhadores"]}>
                    <WinnersIndex currentUser={currentUser} influencer={influencer} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/compras", "/site/compras"]}>
                    <MyOrders currentUser={currentUser} influencer={influencer} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/compras/:orderId", "/site/compras/:orderId"]}>
                    <OrderPage currentUser={currentUser} influencer={influencer} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/campanhas", "/site/campanhas"]}>
                    <CampaignsIndex currentUser={currentUser} influencer={influencer} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/termos", "/site/termos"]}>
                    <Termos content={global_schema.terms} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/politicas", "/site/politicas"]}>
                    <Politicas content={global_schema.politicas} />
                  </Route>
                  <Route exact path={["/:influencer_username/site/faq", "/site/faq"]}>
                    <Faq content={global_schema.faq} />
                  </Route>
                </>
              }
            </div>
          </main>
        </UserContext.Provider>
      </div>
      {navigator.userAgent != "app-ios" ? (
        <Box className={classes.footer}>
          <Footer />
        </Box>) : ("")
      }
    </div>
  );
}
