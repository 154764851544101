import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: '20px',
    backgroundColor: '#120478',
    textAlign: 'left',
    '& span': {
      margin: '10px',
    }
  },
}));

export default function OrderDetailsSkeleton() {
  const classes = useStyles();

  return (
    <div className={classes.skeleton}>
      <Skeleton variant="rect" width="80%" height={30} />
      <Skeleton variant="rect" width="60%" height={30} />
      <Skeleton variant="rect" width="70%" height={30} />
      <Skeleton variant="rect" width="50%" height={30} />
      <Skeleton variant="rect" width="80%" height={30} />
      <Skeleton variant="rect" width="60%" height={30} />
      <Skeleton variant="rect" width="70%" height={30} />
    </div>
  );
};
