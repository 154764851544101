import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Typography from '@material-ui/core/Typography';
import StarIcon from '../../../images/star.svg';
import Telegram from '../../../images/telegram.svg'
import CoelhoIcon from '../../../images/coelho.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0',
    borderRadius: '20px',
    backgroundColor: '#120478',
    border: '1px solid #32EF26',
    padding: '10px 10px',
    minHeight: '100px',
    textAlign: 'left',
  },
  imageContainer: {
    float: 'left',
    width: '100px',
    height: '80px',
    border: '1px solid #32EF26',
    backgroundColor: '#D9D9D9',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      'flex-shrink': '0',
      'width': '100%',
      'height': '100%',
      'object-fit': 'cover'
    },
  },
  infoContainer: {
    marginLeft: '120px',
  },
  title: {
    margin: '10px 0px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
  description: {
    '& span': {
      color: '#32EF26',
      fontWeight: 600
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '70%',
    },
  }
}));

export default function ShortWinner({ campaign }) {
  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <img src={campaign?.drawn_customer?.avatar || CoelhoIcon} />
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="h6" className={classes.title}>
            {campaign?.drawn_customer?.name} ({campaign?.drawn_customer?.cpf})
            &nbsp;
            <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/star.svg" />
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Ganhador(a) com o número da sorte <span>{campaign?.drawn_ticket_number}</span>
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}