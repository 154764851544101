import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme';
import Typography from '@material-ui/core/Typography';
import receiptIcon from '../../images/receipt.svg';
import WithdrawalItem from './WithdrawalItem';
import ResponsiveContainer from '../components/ResponsiveContainer';
import InfoMessage from '../components/InfoMessage';
import refreshIcon from '../../images/refresh.svg';
import axios from 'axios';
import { UserContext } from '../InnerApp';

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: '620px',
  },
  mainHeader: {
    textAlign: 'left',
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    marginBottom: '20px',
    '& small': {
      fontWeight: 400,
      color: '#89E683',
      fontSize: '70%',
      marginLeft: '10px'
    }
  },
  button: {
    color: 'white',
    borderRadius: '20px',
    border: '2px solid #6100C6',
    backgroundColor: 'transparent',
    padding: '8px 20px',
    cursor: 'pointer',
  },
  withdrawalRequests: {
    paddingTop: '30px',
    // minHeight: '600px'
  },
  info: {
    color: 'white',
    margin: '40px',
    textAlign: 'center',
    fontSize: '120%',
    fontWeight: 600
  },
  refreshLink: {
    float: 'right',
  }
}));

export default function Withdrawals({ currentUser, influencer }) {
  const { user, setCurrentUser } = useContext(UserContext);
  const classes = useStyles();
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [finalPage, setFinalPage] = useState(false);

  if (!currentUser)
    window.location.href = '/public/sign_in';

  async function fetchWithdrawalRequests(page) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/withdrawal_requests?page=${page}`);
      if (response.data.length != perPage)
        setFinalPage(true);
      setWithdrawalRequests(withdrawalRequests.concat(response.data));
    } catch (error) {
      setWithdrawalRequests([]);
    } finally {
      setLoading(false);
    }
  }
  async function fetchCurrentUser() {
    const response = await axios.get('/api/v1/customers/current');
    setCurrentUser(response.data);
  }

  function loadMore() {
    fetchWithdrawalRequests(currentPage + 1);
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    fetchWithdrawalRequests(currentPage);
    fetchCurrentUser();
  }, []);

  function refresh() {
    window.location.reload();
  }
  
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveContainer>
        <div className={classes.main}>
          <Typography variant="h5" className={classes.title}>
            <img src={receiptIcon} className={classes.lightningIcon} />&nbsp;
            <a href="#" className={classes.refreshLink} onClick={refresh}>
              <img src={refreshIcon} />
            </a>
            Meus saques
          </Typography>
          <div className={classes.withdrawalRequests}>
            {!loading && withdrawalRequests.map((withdrawalRequest) => (
              <WithdrawalItem key={withdrawalRequest.id} withdrawalRequest={withdrawalRequest} />
            ))}
            {loading && <div className={classes.info}>Carregando...</div>}
            {!loading && withdrawalRequests.length == 0 && <InfoMessage>Nenhum saque encontrado</InfoMessage>}
          </div>
          <div>
            {!finalPage && <button disabled={loading} className={classes.button} onClick={loadMore}>Carregar mais</button>}
          </div>
        </div>
      </ResponsiveContainer>
    </ThemeProvider>
  )
}