import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      border: "1px solid #6600CB",
      backgroundColor: "#091250",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: "bold",
      color: "#FFF",
    },
    "& .MuiButton-root": {
      color: "#091250",
      backgroundColor: "#58DF46",
    },
  },
}));

export default function TermsDialog({ open, setOpen, handleAcceptedTerms }) {
  const classes = useStyles();
  const [accepted, setAccepted] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAccepted(false);
  };

  const handleAgree = (event) => {
    setAccepted(event.target.checked);
    handleAcceptedTerms();
    setTimeout(() => {
      handleClose();
    }, 200);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">{"Atenção!"}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "#FFF" }}
        >
          {`Antes de efetuar o palpite, é indispensável que você possua um cadastro válido em nosso site`}
          &nbsp;
          <u>
            <a href="https://esportesdasorte.com " target="_blank">
              site
            </a>
          </u>
          &nbsp;
          {`no seu CPF para receber o prêmio.`}
          <br />
          <br />
          {`Por favor, leia os`}
          &nbsp;
          <u>
            <a
              href="https://www.esportesdasorte.com/ptb/contents/about-us-gen-terms-cond"
              target="_blank"
            >
              Termos de Uso
            </a>
          </u>
          &nbsp;
          {`disponíveis em nosso site para obter mais informações.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={<Checkbox checked={accepted} onChange={handleAgree} />}
          label={
            <span>
              Li e concordo com os{" "}
              <u>
                <a
                  href="https://www.esportesdasorte.com/ptb/contents/about-us-gen-terms-cond"
                  target="_blank"
                >
                  Termos de Uso
                </a>
              </u>
              .
            </span>
          }
        />
      </DialogActions>
    </Dialog>
  );
}
