import React from 'react';
import { useHistory, Link } from "react-router-dom";
import { Typography, Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoadingButton from '../../../components/LoadingButton';


const useStyles = makeStyles((theme) => ({
   title: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    alignSelf: 'start'
  },
  description: {
    marginTop: '20px',
    alignSelf: 'start',
    fontSize: '14px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #6600CB',
    padding: '32px 32px 20px 32px',
    backgroundColor: '#091250',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  link: { 
    color: '#D2B5FF',
    textDecoration: 'none'
  },
}));

export default function GenericForm({
    title,
    description,
    onSubmit,
    children,
    loading,
    disabled,
    loadingButtonLabel,
    hiddenLoadingButton,
    backButton,
  }) {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.paper}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className={classes.title} component="h1" variant="h5">
          {title || ""}
        </Typography>
        {backButton && (
          <a style={{ cursor: "pointer" }} onClick={history.goBack}>
            <ArrowBackIcon />
          </a>
        )}
      </div>
      <Typography className={classes.description}>
        {description || ""}
      </Typography>
      <form className={classes.form} onSubmit={onSubmit} noValidate>
        {children}
        {!hiddenLoadingButton && (
          <LoadingButton
            id="submitButton"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
            disabled={disabled}
            className={classes.submit}
          >
            {loadingButtonLabel || "Button Name"}
          </LoadingButton>
        )}
      </form>
    </div>
  );
}