import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Typography from '@material-ui/core/Typography';
import StarIcon from '../../../images/star.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '15px 0',
    color: 'white',
    borderRadius: '20px',
    backgroundColor: '#120478',
    border: '1px solid #32EF26',
    padding: '5px 10px',
    textAlign: 'left',
  },
  number: {
    marginTop: '6px',
    float: 'left',
    fontWeight: 'bold',
    width: '30px',
    height: '30px',
    fontSize: '120%',
    textAlign: 'center',
    border: '2px solid #6100C6',
    borderRadius: '50%',
    position: 'relative',
  },
  infoContainer: {
    marginLeft: '40px',
  },
  title: {
    margin: '0px 0px',
    fontWeight: 600,
    fontSize: '90%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
  description: {
    fontSize: '90%',
    '& span': {
      color: '#32EF26',
      fontWeight: 600
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '70%',
    },
  }
}));

export default function ExtraWinner({ winner, index }) {
  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.number}>
          {index}
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="h6" className={classes.title}>
            {winner?.customer?.name}
            &nbsp;
            <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/star.svg" />
          </Typography>
          <Typography variant="body1" className={classes.description}>
            <span>{winner?.ticket_number}</span>
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}