import React, { useState, useEffect } from 'react';
import Winner from '../components/Winner.js';
import axios from 'axios';
import ResponsiveContainer from '../components/ResponsiveContainer';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoMessage from '../components/InfoMessage';
import TrophyIcon from '../../images/trophy.svg';
import { makeStyles } from '@material-ui/core/styles';
import SpecialBanner from '../../images/SpecialBanner.png';
import SpecialBannerMobile from '../../images/SpecialBannerMobile.png';

const useStyles = makeStyles((theme) => ({
  main: {
  },
  mainHeader: {
    textAlign: 'left',
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    marginBottom: '20px',
    '& small': {
      fontWeight: 400,
      color: '#89E683',
      fontSize: '70%',
      marginLeft: '10px'
    }
  },
  button: {
    color: 'white',
    borderRadius: '20px',
    border: '2px solid #6100C6',
    backgroundColor: 'transparent',
    padding: '8px 20px',
    cursor: 'pointer',
  },
  winners: {
    minHeight: '600px'
  },
  info: {
    color: 'white',
    margin: '40px',
    textAlign: 'center',
    fontSize: '120%',
    fontWeight: 600
  }
}));

export default function WinnersIndex({ influencer }) {
  const classes = useStyles();
  const [winners, setWinners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(20);
  const [finalPage, setFinalPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  async function fetchWinners(page) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/campaigns/${influencer?.username}/drawn_tickets.json?page=${page}&per_page=${perPage}`);
      if (response.data.length != perPage)
        setFinalPage(true);
      setWinners(winners.concat(response.data));
    } catch (error) {
      setWinners([]);
    } finally {
      setLoading(false);
    }
  }

  function loadMore() {
    fetchWinners(currentPage + 1);
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    fetchWinners(currentPage);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveContainer>
        <div>
          <Typography variant="h5" className={classes.title}>
            <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/trophy.svg" className={classes.lightningIcon} />&nbsp;
            Ganhadores
            <small>
              Sortudos
            </small>
          </Typography>
          {(influencer?.username == 'virginia' || influencer?.username == 'edscap') && (
            <img src={isMobile ? SpecialBannerMobile : SpecialBanner} style={{ width: '100%' }} />
          )}
          <div className={classes.winners}>
            {!loading && winners.map((winner) => (
              <Winner key={winner.id} winner={winner} influencer={influencer} />
            ))}
            {loading && <div className={classes.info}>Carregando...</div>}
            {!loading && winners.length == 0 && <InfoMessage>Nenhum ganhador encontrado</InfoMessage>}
          </div>
          <div>
            {!finalPage && <button disabled={loading} className={classes.button} onClick={loadMore}>Carregar mais</button>}
          </div>
        </div>
      </ResponsiveContainer>
    </ThemeProvider>
  );
};