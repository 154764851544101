import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Campaign from '../../components/Campaign';
import Typography from '@material-ui/core/Typography';
import LightningIcon from '../../../images/lightning.svg';
import InfoMessage from '../../components/InfoMessage';
import CampaignSkeleton from '../../components/CampaignSkeleton';

const useStyles = makeStyles((theme) => ({
  main: {
  },
  mainHeader: {
    textAlign: 'left'
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    '& small': {
      fontWeight: 400,
      color: '#89E683',
      fontSize: '70%',
      marginLeft: '10px'
    }
  }
}));

export default function Campaigns({ campaigns, loading, currentUser, influencer }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.mainHeader}>
          <Typography variant="h5" className={classes.title}>
            <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/lightning.svg" className={classes.lightningIcon} />&nbsp;
            Campanhas
            <small>
              Escolha sua sorte
            </small>
          </Typography>
          <br />
        </div>
        {loading &&
          <>
            <CampaignSkeleton mode='first' />
            <CampaignSkeleton mode='list' />
            <CampaignSkeleton mode='list' />
          </>
        }
        <div className={classes.campaigns}>
          {!loading && campaigns.length != 0 && (
            campaigns.map((campaign, index) => (
              <Campaign influencer={influencer} campaign={campaign} currentUser={currentUser} key={index} mode={index == 0 ? 'first' : 'list'} />
            )
          ))}
          {!loading && campaigns.length == 0 && (
            <InfoMessage>Nenhuma campanha encontrada</InfoMessage>
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}