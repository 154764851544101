import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RdmLogo from '../images/logo.rdmapps.png';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: '20px',
  },
  footerLogo: {
    height: '80px',
    margin: '10px 60px'
  },
  socialMediaIcon: {
    margin: '0 30px'
  },
  capemisaText: {
    fontSize: '80%',
    margin: '10px 40px 0 40px',
    padding: '10px 40px 30px 40px',
    color: 'white',
    '& a:link': {
      color: 'white'
    },
    '& a:visited': {
      color: 'white'
    }
  }
}));

export default function OnaBetFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/eds_cap.png" className={classes.footerLogo} />
      <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/capemisa.png" className={classes.footerLogo} />
      <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/apae.png" className={classes.footerLogo} />
      <div className={classes.socialMedia}>
        <a className={classes.socialMediaIcon} href="https://www.instagram.com/edscap" target="_blank" rel="noopener noreferrer">
          <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/instagram.png" height="25" width="25" />
        </a>
        {/* <a className={classes.socialMediaIcon} href="https://www.threads.net/@esportesdasorte" target="_blank" rel="noopener noreferrer">
          <img src={threads} height="25" width="25" />
        </a>
        <a className={classes.socialMediaIcon} href="https://twitter.com/EsportesDaSorte" target="_blank" rel="noopener noreferrer">
          <img src={twitter} height="25" width="25" />
        </a> */}
      </div>
      <div className={classes.capemisaText}>
      Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única, 
        emitido pela CAPEMISA Capitalização S/A, CNPJ 14.056.028/0001-55, aprovado pelo
        Processo SUSEP que consta no Título. SAC 0800 291 2246 ou 0800 940 1130.
        OUVIDORIA 0800 291 2248 ou 0800 707 4936, de segunda a sexta-feira, das 8h às 17h.
        É proibida a venda de título de capitalização a menores de dezesseis anos.
        O valor não exigido dentro do prazo prescricional, estabelecido pela legislação
        em vigor, acarretará a perda desse direito. A aquisição deste título implica a
        cessão de 100% do direito de resgate à APAE BRASIL, certificada nos termos da
        legislação em vigor. Antes de contratar consulte previamente as Condições Gerais.
        As condições contratuais/regulamento deste produto protocolizadas pela sociedade
        junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br,
        de acordo com o número de processo constante da proposta. Consulte as informações
        legais da Resolução CNSP 382/2020 em www.capemisa.com.br. Prêmios líquidos de
        imposto de renda. Confira o resultado dos sorteios e as condições de participação
        em www.edscap.com.br.
        <br />
        Imagens meramente ilustrativas.
        <br/>
        <br/>
        <a href="/site/termos" target="_blank" rel="noopener noreferrer">Termos de Uso</a> | <a href="/site/politicas" target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
        <br/>
        <br/>
        Edscap LTDA - 52.559.942/0001-08
        <br/>
        <br/>
        Sistema desenvolvido pela &nbsp;
        <a target="_blank" href="https://www.rdmapps.com.br/">
          <img height="20" src={RdmLogo} style={ { transform: "translateY(5px)"}} ></img>
        </a>
      </div>
    </div>
  );
}