import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Typography from '@material-ui/core/Typography';
import CoelhoIcon from '../../../images/coelho.jpg';
import { numberAsCurrency } from '../../utils';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0',
    borderRadius: '20px',
    backgroundColor: '#120478',
    border: '1px solid #32EF26',
    padding: '10px 10px',
    minHeight: '100px',
    textAlign: 'left',
  },
  imageContainer: {
    float: 'left',
    width: '100px',
    height: '100px',
    border: '1px solid #32EF26',
    backgroundColor: '#D9D9D9',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      'flex-shrink': '0',
      'width': '100%',
      'height': '100%',
      'object-fit': 'cover'
    },
    [theme.breakpoints.down('xs')]: {
      height: '80px',
    }
  },
  infoContainer: {
    marginLeft: '120px',
  },
  title: {
    margin: '0px 0px 0px 0px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
  description: {
    '& span': {
      color: '#D8B2FF',
      fontWeight: 600
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '70%',
    },
  }
}));

export default function InstantWinner({ winner, campaign }) {
  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <img src={winner?.customer?.avatar || CoelhoIcon} />
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="h6" className={classes.title}>
            {winner?.customer?.name} ({winner?.customer?.cpf})
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Prêmio instantâneo de {numberAsCurrency(winner?.instant_prize_value)}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Número da sorte <span>{winner?.pretty_number}</span>
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Data da premiação <span>{moment(winner?.created_at).format('DD/MM/YY [às] HH:mm')}</span>
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}