import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";

//
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      border: "1px solid #6600CB",
      backgroundColor: "#091250",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: "bold",
      color: "#FFF",
    },
    "& .MuiButton-root": {
      color: "#091250",
      backgroundColor: "#58DF46",
    },
  },
}));

export default function RegisteredDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const history = useHistory();

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">
        {"Você já é cadastrado em nosso site?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "#FFF" }}
        >
          {`É desejável estar cadastrado no site `}

          <a href="https://go.aff.esportesdasorte.com/r7gspoqw" target="_blank">
            site.
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Já sou cadastrado</Button>
        <Button onClick={handleAgree} autoFocus>
          Quero me cadastrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
