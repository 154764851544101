import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Typography from '@material-ui/core/Typography';
import { numberAsCurrency } from '../../utils';
import MoneyBagIcon from '../../../images/moneyBag.svg';
import BigWalletIcon from '../../../images/big_wallet.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    backgroundColor: '#120478',
    padding: '20px',
    color: 'white',
    margin: '20px 0'
  },
  title: {
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '110%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  bigButton: {
    backgroundColor: '#0E0552',
    // margin: '1%',
    touchAction: 'manipulation',
    color: 'white',
    fontSize: '180%',
    fontWeight: 600,
    borderRadius: '20px',
    height: '100px',
    display: 'inline-block',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    margin: '10px 5px',
    // flex: '46%',
    flexBasis: 'calc(50% - 20px)',
    padding: '10px 20px',
    '& small': {
      color: 'white',
      display: 'block',
      fontWeight: 400,
      fontSize: '70%',
      textTransform: 'uppercase'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '140%',
    },
  },
  mainButton: {
    border: '2px solid #32EF26',
    position: 'relative',
    '&:before': {
      content: '"mais popular"',
      position: 'absolute',
      fontSize: '50%',
      top: '-10px',
      left: '-10px',
      borderRadius: '10px',
      color: 'black',
      backgroundColor: '#32EF26',
      fontWeight: 400,
      padding: '3px 8px'
    }
  },
  fineSelection: {
    // display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    color: 'white',
    fontWeight: 400,
    borderRadius: '50%',
    touchAction: 'manipulation',
    cursor: 'pointer',
    padding: '10px',
    fontSize: '300%',
    position: 'relative',
    margin: '0 10px',
    backgroundColor: 'transparent',
    width: '70px',
    height: '70px',
    lineHeight: '10px',
    border: '2px solid #6100C6',
    '& span': {
      position: 'relative',
      top: '-4px'
    },
  },
  selectedTickets: {
    fontWeight: 600,
    // width: '100%',
    display: 'block',
    borderRadius: '20px',
    background: 'linear-gradient(90deg, #0F1896 -10.57%, #6600CB 91.15%)',
    padding: '20px 20px',
    fontSize: '150%',
    margin: '0 90px'
  },
  buyButton: {
    background: 'linear-gradient(90deg, #0F1896 -10.57%, #6600CB 91.15%)',
    borderRadius: '30px',
    padding: '30px',
    border: 'none',
    color: 'white',
    width: '100%',
    fontSize: '140%',
    margin: '20px 0 5px 0',
    textAlign: 'left',
    cursor: 'pointer',
    '& b': {
      marginLeft: '20px',
      lineHeight: '150%'
    },
    '& img': {
      float: 'left',
    },
    '& span': {
      float: 'right',
      fontSize: '110%',
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100%',
      padding: '30px 20px'
    },
  },
  fastBuyButton: {
    backgroundColor: '#50ef25',
    borderRadius: '30px',
    padding: '20px',
    border: 'none',
    color: '#0f1796',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontSize: '140%',
    margin: '5px 0 20px 0',
    textAlign: 'center',
    cursor: 'pointer',
    '& b': {
      marginLeft: '20px',
      lineHeight: '150%'
    },
    '& img': {
      // float: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100%',
      borderRadius: '15px',
      padding: '15px 20px'
    },
  },
}));

export default function SelectionBox({ selectedTickets, setSelectedTickets, purchaseCallback, fastPurchaseCallback, campaign, loading }) {
  const classes = useStyles();
  const [firstClick, setFirstClick] = React.useState(true);

  function numberButtonCallback(number) {
    if (firstClick) {
      setSelectedTickets(parseInt(number.replace('*', '')))
    } else {
      setSelectedTickets(selectedTickets + parseInt(number.replace('*', '')))
    }
    setFirstClick(false);
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Typography variant="h5" className={classes.title}>
          Quanto mais títulos comprar, maiores são as chances de ganhar!
        </Typography>
        <div className={classes.buttonsContainer}>
          {
            campaign?.buttons_array.map((amount, index) => (
              <button disabled={loading} key={index} className={`${classes.bigButton} ${amount.includes("*") && classes.mainButton}`} onClick={() => numberButtonCallback(amount)}>
                +{amount.replace('*', '')}
                <small>Selecionar</small>
              </button>
            ))
          }
        </div>
        <div className={classes.fineSelection}>
          <button disabled={loading} style={{float: 'left'}} className={classes.button} onClick={() => setSelectedTickets(selectedTickets - 1)}><span>-</span></button>
          <button disabled={loading} style={{float: 'right'}} className={classes.button} onClick={() => setSelectedTickets(selectedTickets + 1)}><span>+</span></button>
          <span className={classes.selectedTickets}>{selectedTickets}</span>
        </div>
      </div>
      <button disabled={loading} className={classes.buyButton} onClick={() => purchaseCallback()}>
        <span>{numberAsCurrency(selectedTickets * campaign?.ticket_price)}</span>
        <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/moneyBag.svg" />
        <b>Quero participar</b>
      </button>
      <button disabled={loading} className={classes.fastBuyButton} onClick={() => fastPurchaseCallback()}>
        <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/big_wallet.svg" />
        <b>Compra rápida</b>
      </button>
    </ThemeProvider>
  )
}