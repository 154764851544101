import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import LoadingButton from '../../components/LoadingButton'
import { TextMaskCPF } from '../../components/TextMaskCustom';

const useStyles = makeStyles((theme) => ({
  newAccount: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    alignSelf: 'start'
  },
  newAccountFooter: {
    marginTop: '20px',
    alignSelf: 'start',
    fontSize: '14px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #6600CB',
    padding: '32px',
    backgroundColor: '#091250',
  },
  sendCpfBtn: {
    fontSize: '12px',
    padding: '17px 5px',
    marginTop: '9px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  cpfDiv: {
    color: '#fff',
    display: 'block'
  },
  cpfDivHeader: {
    textAlign: '-webkit-center',
    marginTop: '30px'
  },
  cpfDivInfo: {
    float: 'left',
    "@media(max-width: 425px)": {
      marginBottom: '20px'
    }
  },
  cpfDivName: {
    fontSize: '1.5rem'
  },
}));

export default function DocumentConfirmation() {
  const classes = useStyles();
  const [user, setUser] = useState({ cpf: '', confirmed: false });
  const [isDisabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [continuarLoading, setContinuarLoading] = useState(false);
  const [enviarLoading, setEnviarLoading] = useState(false);

  const [returnMessage, setReturnMessage] = useState();
  
  function completeDocumentRegistration(e) {
    e.preventDefault();

    setErrors({});
    setContinuarLoading(true);
    var cpf = user.cpf;
    axios
      .put('/api/v1/customers/confirm_cpf', { customer: { cpf } })
      .then(function (response) {
        console.log(response?.status);
        window.location.href = '/site';
      })
      .catch(function ({ response }) {
        if (response?.status == 422) {
          setErrors({ ...errors, cpf: response.data?.cpf });
          setDisabled(true);
        }
      })
      .then(function () {
        setContinuarLoading(false);
      });
  };

  function sendCpf() {
    setErrors({});
    setReturnMessage();
    var cpf = user.cpf;
    if (cpf){
      setEnviarLoading(true);
      axios
        .post('/api/v1/customers/verify_cpf', { customer: { cpf } })
        .then(function (response) {
          setReturnMessage(response.data?.message?.name);
          setDisabled(false);
        })
        .catch(function ({ response }) {
          if (response?.status == 422) {
            setErrors({ ...errors, cpf: response.data?.errors?.cpf });
            setDisabled(true);
          }
        })
        .then(function () {
          setEnviarLoading(false);
        });
    } else {
      setErrors({ ...errors, cpf: "Campo obrigatório!" });
    }
  }

  // lib do token seta o cursor para o campo, o useEffect tras para o primeiro campo
  useEffect(() => {
    document.getElementById('cpf').focus();
    document.getElementById("submitButton").disabled = true;
  }, []);

  return (
    <div className={classes.paper}>
      <Typography className={classes.newAccount} component="h1" variant="h5">
        Confirme seu CPF
      </Typography>
      <Typography className={classes.newAccountFooter}>
        É necessário confirmar o CPF antes de prosseguir
      </Typography>
      <form className={classes.form} onSubmit={completeDocumentRegistration} noValidate>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required={true}
            key={'cpf'}
            id={'cpf'}
            name={'cpf'}
            label={'CPF'}
            fullWidth={true}
            autoComplete={'cpf'}
            autoFocus={true}
            value={user['cpf']}
            error={!!errors.cpf}
            type={'phone'}
            helperText={errors['cpf'] ? errors['cpf'] : ''}
            onChange={(e) => setUser({ ...user, ['cpf']: e.target.value })}
            InputProps={{
              inputComponent: TextMaskCPF,
            }}
          />
          <LoadingButton
            onClick={sendCpf}
            fullWidth
            loading={enviarLoading}
            variant="contained"
            color="primary"
            className={classes.sendCpfBtn}
          >
            ENVIAR
          </LoadingButton>
        </div>
        {returnMessage ?
          <div className={classes.cpfDiv}>
            <div className={classes.cpfDivHeader}>
              <span className={classes.cpfDivInfo}>{returnMessage ? "Se este é o seu nome, prossiga:" : ""}</span><br /><br />
              <span className={classes.cpfDivName}>{returnMessage}</span>
            </div>
          </div>
          :
          ""
        }
        <LoadingButton
          type="submit"
          disabled={isDisabled}
          fullWidth
          loading={continuarLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          id="submitButton"
        >
          CONTINUAR
        </LoadingButton>
      </form>
    </div>
  );
}
