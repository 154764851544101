import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CoelhoIcon from '../../images/coelho.jpg';
import moment from "moment";
import { useHistory } from 'react-router-dom';

//
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    backgroundColor: '#120478',
    overflow: 'hidden',
    marginBottom: '20px',
    display: 'flex',
    cursor: 'pointer'
  },
  imageContainer: {
    float: 'left',
    width: '15%',
    margin: '20px 10px 20px 20px',
    border: '1px solid #32EF26',
    backgroundColor: '#D9D9D9',
    minWidth: '100px',
    maxHeight: '110px',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      'flex-shrink': '0',
      'width': '100%',
      'height': '100%',
      'object-fit': 'cover'
    },
  },
  details: {
    padding: '15px',
    textAlign: 'left',
    width: '100%'
  },
  title: {
    fontSize: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
  subtitle: {
    color: '#89E683',
    fontWeight: 900,
    fontSize: '90%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '70%',
    },
  },
  code: {
    '& b': {
      color: '#89E683'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
  number: {
    '& b': {
      color: '#D8B2FF'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
  date: {
    '& b': {
      color: 'white'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '80%',
    },
  },
}));

export default function Winner({winner, currentUser, influencer, mode = 'list'}) {
  const classes = useStyles();
  const history = useHistory();
  moment.locale('pt-BR');

  function handleClick() {
    history.push(`/${influencer.username}/site/campanhas/${winner.id}`);
  }

  return (
    <div className={mode == 'list' ? classes.root : classes.rootFirst} onClick={handleClick}>
      <div className={mode == 'list' ? classes.imageContainer : classes.imageContainerFirst}>
        <img src={winner.drawn_customer.avatar || "https://d2zdlj1ifv8u1d.cloudfront.net/images/coelho.jpg"} />
      </div>
      <div className={mode == 'list' ? classes.details : classes.detailsFirst}>
        <Typography className={classes.title} component="h5" variant="h5">
          {winner.drawn_customer.name} ({winner.drawn_customer.cpf})
        </Typography>
        <Typography className={classes.subtitle} variant="body1">
          {winner.title}
        </Typography>
        <Typography className={classes.number} variant="body1">
          Número da sorte: <b>{winner.drawn_ticket_number}</b>
        </Typography>
        <Typography className={classes.date} variant="body1">
          Data da premiação: <b>{moment(winner.draw_time).format("DD/MM/YYYY")}</b>
        </Typography>
        <Typography className={classes.code} variant="body1">
          Sorteio: <b>{winner.code}</b>
        </Typography>
      </div>
    </div>
  );
}
