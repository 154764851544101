import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme';
import Typography from '@material-ui/core/Typography';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  main: {
    textAlign: 'center',
    padding: theme.spacing(3),
    color: 'white',
    textAlign: 'left',
    margin: '2% 23%',
    '@media(max-width: 1468px)': {
      margin: '2% 13%',
    },
    '@media(max-width: 768px)': {
      margin: '2% 0%',
    }
  },
  welcome: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    color: '#58DF46',
    // width: '45%',
    '@media(max-width: 768px)': {
      width: '100%',
    }
  },
  icons: {
    fill: '#58DF46',
    fontSize: '1.75em',
    verticalAlign: 'bottom',
    marginRight: '10px'
  },
  mainHeader: {
    textAlign: 'left',
  },
}));

export default function Politicas({content}) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.mainHeader}>
          <Typography variant="h5" className={classes.welcome}>
            <LibraryBooksIcon className={classes.icons} />
            Política de Privacidade da EDSCap
          </Typography>
          <br />
        </div>
        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: content.body }} />
      </div>
    </ThemeProvider>
  )
}