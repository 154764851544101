import React, { useState, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import MaterialTable, { MTableToolbar, MTableCell } from 'material-table';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import Helpers from './Helpers';
import LoadingButton from '../../components/LoadingButton';
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root .Mui-disabled': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiTable-root': {
      marginTop: 16,
      marginBottom: 8,
    },
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
    },
    '& .MuiToolbar-gutters > div:nth-child(1)': {
      display: 'none',
    },
    '& .MuiToolbar-gutters > div:nth-child(2)': {
      width: '100%',
    },
    '& .MuiToolbar-gutters > div:nth-child(2) > div > div > span > button': {
      width: '100%',
      paddingRight: 0,
      paddingLeft: 0,
    },
    '& .MuiToolbar-gutters > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > span:nth-child(1) > button:nth-child(1) > span:nth-child(1)':
      {
        justifyContent: 'flex-end',
        '@media(max-width: 959px)': {
          justifyContent: 'flex-start',
        },
      },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiPaper-rounded': {
      padding: '0px 22px',
      borderRadius: 0,
      backgroundColor: 'transparent !important'
    },
    '& .MuiToolbar-root': {
      height: '64px !important',
    },
    '& .MuiTypography-h6': {
      width: 400,
      textTransform: 'uppercase',
    },
    '& tbody': {
      '& .MuiTableRow-root': {
        fontFamily: 'Roboto, sans-serif',
        '& .MuiTableCell-root': {
          border: '1px solid #DDDFE1',
          padding: '8px',
        },
      },
    },
    '& .free-action__div': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .free-action__div .MuiButton-text': {
      fontSize: '.8rem',
      '@media(max-width: 395px)': {
        fontSize: '.7rem',
      },
      '@media(max-width: 372px)': {
        fontSize: '.6rem',
      },
    },
  },
  title: {
    '& h6': {
      fontSize: '1rem',
      color: '#5A7184',
      margin: 0,
    },
    '& small': {
      color: '#ABAFB3',
      '@media(max-width: 600px)': {
        display: 'none',
      },
    },
  },
}));

export function ActionButton({
  children,
  buttonStyles = { backgroundColor: '#fc842b' },
  rootStyles = {},
  ...props
}) {
  return (
    <LoadingButton
      style={{
        color: '#FFFFFF',
        borderRadius: '3px',
        ...buttonStyles,
      }}
      rootStyle={{ marginLeft: 0, ...rootStyles }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}

export default function MTable({
  apiURL = '',
  title = '',
  columns = [{}],
  rows = [{}],
  actions = [{}],
  pageSize = 8,
  newItemCreated = 0,
  showPagination = true,
  setHeaderTotal = undefined,
  headerTotal,
  columnForTotal = 'total_cost',
  showCustomFilter = false,
  filterColumns = [],
  setCustomFilterUrl = undefined,
  customFilterUrl = '',
  itemDetails = false,
  external = false,
}) {
  const [loading, setLoading] = useState(true);
  const [canPaginate, setCanPaginate] = useState(false);
  // This state controls whether we should skip the first load, when there is a URL filter
  const [firstLoad, setFirstLoad] = useState(true);

  const theme = useTheme();
  const classes = useStyles();
  const tableRef = useRef();
  
  let query = useQuery();

  // const { globalMenuOpen } = !external ? true : false;

  function hideEmptyRows() {
    var trs = document.getElementsByClassName('MuiTableRow-root');
    for (var tr of trs) {
      var hide = false;

      if (tr.innerHTML.length === 0) hide = true;

      if (hide) tr.style.display = 'none';
    }
  }

  function getFilterObjectFromQueryString() {
    try {
      return rison.decode(query.get('filter'));
    } catch(e) {
      console.log('Ignoring filters in URL');
      return [];
    }
  }

  const filters = getFilterObjectFromQueryString();

  useEffect(() => {
    const needRedirection =
      tableRef.current.dataManager.getRenderState().renderData.length === 1;
    const currentPage = tableRef.current.dataManager.currentPage;
    if (needRedirection) {
      // why if needRedirection, currentPage seems always zero
      tableRef.current.onQueryChange({ page: currentPage });
    } else {
      if (newItemCreated || customFilterUrl) {
        tableRef.current.onQueryChange();
      }
    }
  }, [newItemCreated, customFilterUrl]);

  useLayoutEffect(() => {
    tableRef.current?.dataManager?.sortedData &&
      tableRef.current.dataManager.changePageSize(
        tableRef.current?.dataManager?.sortedData.length > 0
          ? tableRef.current?.dataManager?.sortedData.length <= pageSize
            ? tableRef.current?.dataManager?.sortedData.length
            : pageSize
          : 0
      );

    setCanPaginate(tableRef.current?.dataManager.pageSize > 0 ? true : false);
  }, [tableRef.current?.dataManager]);

  useLayoutEffect(() => {
    setInterval(hideEmptyRows, 1000);
  }, []);

  const doCallback = useCallback((rows) => {
    if (setHeaderTotal) {
      const total =
        rows?.length > 0
          ? rows
              ?.map((v) => parseFloat(v[columnForTotal]))
              .reduce(function (soma, i) {
                return soma + i;
              })
          : 0;
      setHeaderTotal(total);
    }
  }, []);

  //Se a props showPagination for false vai ser ignorado paginação e perpage
  const newPageSize = showPagination === false ? 1000 : pageSize;

  return (
    <div className={classes.root}>
      <Paper variant="transparent" square>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          // style={globalMenuOpen ? { display: 'grid' } : ''}
          // data={rows}
          detailPanel={ itemDetails ? [
            {
              tooltip: 'Detalhes',
              render: response => {
                return (
                  <div
                    style={{
                      fontSize: 16,
                      textAlign: 'center',
                      color: 'white',
                      padding: '5px 15px',
                      backgroundColor: '#091250',
                    }}
                  >
                    <Typography style={{ textAlign: 'left' }}>{'DETALHAMENTO DOS JOGOS:'}</Typography>
                    {response.more_info_details.map((match) =>
                      <Typography>{match}</Typography>
                    )}
                  </div>
                )
              }
            }
          ] : '' }
          data={(query) =>
            new Promise((resolve, reject) => {
              hideEmptyRows();
              if (firstLoad && filters && filters.length > 0) {
                setFirstLoad(false);
                return resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              }
              let url = apiURL;
              if (apiURL == '') {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              }
              setLoading(true);
              url += 'per_page=' + newPageSize;
              url += '&page=' + (query.page + 1);
              if (query.search) {
                url += '&search=' + query.search;
              }
              if (query.orderBy) {
                url +=
                  '&q[s]=' + query.orderBy.field + '+' + query.orderDirection;
              }
              if (customFilterUrl !== '') {
                url += customFilterUrl;
              }
              fetch(url)
                .then((response) => {
                  return response.json();
                })
                .then((result) => {
                  doCallback(result.data);
                  tableRef.current?.dataManager?.changePageSize(pageSize);
                  setCanPaginate(
                    tableRef.current?.dataManager.pageSize > 0 ? true : false
                  );
                  resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                })
                .catch((error) => {
                  setLoading(false);

                  return null;
                })
                .then(setLoading(false))
                .then(hideEmptyRows());
            })
          }
          // actions={actions}
          components={{
            Toolbar: (props) => {
              // This will let you use your own Title while keeping the search
              const propsCopy = { ...props };
              // Hide default title
              propsCopy.showTitle = false;
              return (
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <h2>{`${title} ${
                      headerTotal
                        ? ` - Valor Total ${Helpers.currency_br(headerTotal)}`
                        : ''
                    }`}</h2>
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    {/* <RemoveCircleIcon /> */}
                    <MTableToolbar {...propsCopy} />
                  </Grid>
                </Grid>
              );
            },
            Cell: (props) => {
              // if (typeof props.value !== 'string')
              return <MTableCell {...props} />;

              // return (
              // <Tooltip title={props.value ? props.value : ''}>
              // <MTableCell {...props} />
              // </Tooltip>
              // );
            },
          }}
          options={{
            sorting: false,
            paging: showPagination ? canPaginate : false,
            searchFieldStyle: { borderBottom: '1px solid' },
            showEmptyDataSourceMessage: !loading,
            search: false,
            //actionsColumnIndex: -1,
            paginationPosition: 'both',
            pageSize: newPageSize,
            debounceInterval: 400,
            pageSizeOptions: [], //Desativa o select com quantidade por linhas
            rowStyle: (data, index) => {
              if (!(index % 2)) {
                return { backgroundColor: '#091250' };
              }
            },
            cellStyle: {
              textOverflow: 'ellipsis',
              whiteSpace: 'wrap',
              overflow: 'hidden',
              // maxWidth: 200, // Não funciona
              // maxHeight: 80 // Não funciona
            },
            headerStyle: {
              border: '1px solid #6600CB',
              color: '#fff',
              backgroundColor: '#091250',
              whiteSpace: 'nowrap',
              fontSize: '16px',
              padding: '8px',
              fontWeight: 'bold',
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Nenhum registro para exibir',
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: '',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página',
            },
            header: {
              actions: 'Ações',
            },
          }}
        />
      </Paper>
    </div>
  );
}
