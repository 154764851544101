import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CalendarIcon from "../../images/calendar.svg";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

//
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    backgroundColor: '#120478',
    overflow: 'hidden',
    marginBottom: '20px',
    display: 'flex'
  },
  rootFirst: {
    borderRadius: '20px',
    backgroundColor: '#120478',
    overflow: 'hidden',
    marginBottom: '20px'
  },
  imageContainerFirst: {
    height: '200px',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
  },
  imageContainer: {
    float: 'left',
    width: '20%',
    minWidth: '150px',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '140px',
      minWidth: 'unset'
    },
    '& img': {
      'flex-shrink': '0',
      'width': '100%',
      'height': '100%',
      'object-fit': 'cover'
    },
  },
  detailsFirst: {
    padding: '15px',
    textAlign: 'left',
    width: '100%'
  },
  details: {
    padding: '15px',
    textAlign: 'left',
    width: '100%'
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '100%',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      overflow: 'hidden'
    },
  },
  subtitle: {
    color: '#C6A5FF',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '90%',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
      display: '-webkit-box',
      overflow: 'hidden'
    },
  },
  code: {
    '& b': {
      color: '#89E683'
    },
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '90%',
      display: 'none',
    },
  },
  codeFirst: {
    '& b': {
      color: '#89E683'
    },
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '90%',
    },
  },
  status: {
    borderRadius: '10px',
    padding: '0px 20px 2px 20px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '90%',
      padding: '0px 10px 2px 10px'
    },
    animation: '$blink 0.8s infinite',
  },
  '@keyframes blink': {
    from: { opacity: 1.0 },
    '50%': { opacity: 0.5 },
    to: { opacity: 1.0 },
  },
  deadline: {
    color: 'white',
    float: 'right',
    position: 'relative',
    top: '-3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '90%',
      float: 'none',
      display: 'block'
    },
    '& img': {
      position: 'relative',
      top: '2px',
    }
  },
}));

export default function Campaign({campaign, currentUser, influencer, mode = 'list', link = true}) {
  const classes = useStyles();
  const history = useHistory();
  moment.locale('pt-BR');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function handleClick() {
    if (link)
      history.push(`/${influencer?.username || campaign.influencer_username}/site/campanhas/${campaign.id}`);
  }

  function backgroundForStatus(status) {
    switch (status) {
      case 'finished':
        return '#ffffff';
      case 'waiting_drawn':
        return '#ffffff';
      case 'sold_out':
        return '#ffffff';
      case 'waiting_start':
        return '#ffffff';
      default:
        return '#75ec50';
    }
  }

  function animationForStatus(status) {
    switch (status) {
      case 'near_time_limit':
        return '$blink 1s infinite';
      case 'near_tickets_limit':
        return '$blink 1s infinite';
      default:
        return 'none';
    }
  }

  function colorForStatus(status) {
    switch (status) {
      case 'near_time_limit':
        return 'red';
      case 'near_tickets_limit':
        return 'red';
      default:
        return '#120478';
    }
  }

  if (!campaign)
    return null;

  return (
    <div className={mode == 'list' ? classes.root : classes.rootFirst}
         onClick={handleClick}
         style={link ? {cursor: 'pointer'} : {}}>
      <div className={mode == 'list' ? classes.imageContainer : classes.imageContainerFirst}>
        <img src={
          mode == 'list' ? 
            campaign.square_banner_url :
            (isMobile ? campaign.mobile_banner_url : campaign.main_banner_url)
          } />
      </div>
      <div className={mode == 'list' ? classes.details : classes.detailsFirst}>
        <Typography className={classes.title} component="h5" variant="h5">
          {campaign.title}
        </Typography>
        <Typography className={classes.subtitle} variant="body1">
          {campaign.subtitle}
        </Typography>
        <Typography className={mode == 'list' ? classes.code : classes.codeFirst} variant="body1">
          Produto: <b>{campaign.code}</b>
        </Typography>
        <span className={classes.deadline}>
          <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/calendar.svg" />&nbsp;
          vendas até {moment(campaign.end_time).format('DD/MM/YY [às] HH:mm')}
        </span>
        <span className={`${classes.status} blink`}
              style={{
                backgroundColor: backgroundForStatus(campaign.user_status),
                color: colorForStatus(campaign.user_status),
                animation: animationForStatus(campaign.user_status)
              }}>
          {link ? campaign.t_user_status : campaign?.t_user_status?.replace('Clique e ', '')}
        </span>
      </div>
    </div>
  );
}
