import React, { useState, useEffect } from 'react';
import Campaign from '../components/Campaign.js';
import axios from 'axios';
import ResponsiveContainer from '../components/ResponsiveContainer.js';
import InfoMessage from '../components/InfoMessage.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme.js';
import Typography from '@material-ui/core/Typography';
import LightningIcon from '../../images/lightning.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
  },
  mainHeader: {
    textAlign: 'left',
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    marginBottom: '20px',
    '& small': {
      fontWeight: 400,
      color: '#89E683',
      fontSize: '70%',
      marginLeft: '10px'
    }
  },
  button: {
    color: 'white',
    borderRadius: '20px',
    border: '2px solid #6100C6',
    backgroundColor: 'transparent',
    padding: '8px 20px',
    cursor: 'pointer',
  },
  filters: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#0E0552',
    '& button': {
      border: 'none',
      backgroundColor: 'white',
      padding: '8px 20px',
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: '20px 0 0 20px',
      },
      '&:last-child': {
        borderRadius: '0 20px 20px 0',
      },
    },
  },
  selected: {
    color: 'white',
    background: 'linear-gradient(90deg, #0F1896 -10.57%, #6600CB 91.15%);'
  },
  orders: {
    minHeight: '600px'
  },
  info: {
    color: 'white',
    margin: '40px',
    textAlign: 'center',
    fontSize: '120%',
    fontWeight: 600
  }
}));

export default function CampaignsIndex({ influencer }) {
  const classes = useStyles();
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(20);
  const [finalPage, setFinalPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchButton, setSearchButton] = useState('active');

  async function fetchCampaigns(page) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/campaigns/${influencer.username}/history.json?page=${page}&per_page=${perPage}&type=${searchButton}`);
      if (response.data.length != perPage)
        setFinalPage(true);
      setCampaigns(campaigns.concat(response.data));
    } catch (error) {
      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  }

  function loadMore() {
    fetchCampaigns(currentPage + 1);
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    fetchCampaigns(currentPage);
  }, []);

  useEffect(() => {
    fetchCampaigns(1);
  }, [searchButton]);

  function search(term) {
    setFinalPage(false);
    setCampaigns([]);
    setCurrentPage(1);
    setSearchButton(term);
  }

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveContainer>
        <div>
          <Typography variant="h5" className={classes.title}>
            <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/lightning.svg" className={classes.lightningIcon} />&nbsp;
            Campanhas
          </Typography>
          <div className={classes.filters}>
            <button onClick={() => search('active')} className={searchButton == 'active' ? classes.selected : null}>Ativas</button>
            <button onClick={() => search('past')} className={searchButton != 'active' ? classes.selected : null}>Encerradas</button>
          </div>
          <div className={classes.campaigns}>
            {!loading && campaigns.map((campaign) => (
              <Campaign key={campaign.id} campaign={campaign} />
            ))}
            {loading && <div className={classes.info}>Carregando...</div>}
            {!loading && campaigns.length == 0 && <InfoMessage>Nenhuma campanha encontrada</InfoMessage>}
          </div>
          <div>
            {!finalPage && <button disabled={loading} className={classes.button} onClick={loadMore}>Carregar mais</button>}
          </div>
        </div>
      </ResponsiveContainer>
    </ThemeProvider>
  );
};