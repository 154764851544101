import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  main: {
    textAlign: 'center',
    '& .MuiDataGrid-root': {
      border: 'transparent',
      backgroundColor: 'transparent'
    },
    '& .MuiDataGrid-columnsContainer': {
      background: 'transparent linear-gradient(92deg, #091250 0%, #6600CB 100%) 0% 0% no-repeat padding-box',
      borderRadius: '21px',
      border: '1px solid #6600CB',
      width: 'fit-content',
      '@media(max-width: 1400px)': {
        width: 'auto',
      }
    },
    '& .MuiDataGrid-row': {
      marginTop: '10px',
      border: '1px solid #6600CB',
      borderRadius: '21px',
      backgroundColor: '#091250',
      '&:hover': { backgroundColor: '#091250' }
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#091250',
      '&:hover': { backgroundColor: '#091250' }
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
      textAlign: 'center',
      borderRight: '1px solid #6600CB',
      '&:focus': { outline: 'none' }
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-columnHeader': {
      borderRight: '1px solid #6600CB'
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'center'
    },
    '& .MuiDataGrid-iconButtonContainer': {
      display: 'none'
    },
    '& .MuiDataGrid-withBorder': {
      borderRight: 'none'
    },
    '& .MuiDataGrid-viewport': {
      overflow: 'unset'
    },
    '& .MuiDataGrid-window': {
      marginBottom: '-100px'
    },
    '& .MuiDataGrid-footerContainer': {
      marginTop: '120px'
    },
    '& .MuiDataGrid-overlay': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiDataGrid-root *, .MuiDataGrid-root *::before, .MuiDataGrid-root *::after': {
      color: '#fff !important'
    },
    '& .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount': {
      color: 'transparent !important'
    }
  },
  mainHeader: {
    textAlign: 'left',
  },
  welcome: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    color: '#58DF46',
    width: '65%',
    '@media(max-width: 768px)': {
      width: '100%',
    }
  },
  icons: {
    fill: '#58DF46',
    fontSize: '1.75em',
    verticalAlign: 'bottom',
    marginRight: '10px'
  }
}));

export default function GenericTable({ columns = [{}], rows = [{}], hideFooter }) {
  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          autoHeight={true}
          width={"auto"}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableExtendRowFullWidth={true}
          hideFooterPagination={hideFooter}
          localeText={{ noRowsLabel: 'Sem dados disponíveis' }}
        />
      </div>
    </ThemeProvider>
  )
}