import React from 'react';
import InfoIcon from '../../images/info.svg';

export default function InfoMessage(props) {
  return (
    <div style={{ 
      backgroundColor: '#120478',
      border: '1px solid #995CFF',
      borderRadius: '15px',
      padding: '10px 20px', 
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/info.svg" style={{
        width: '20px',
        height: '20px',
        marginRight: '10px',
      }} />
      {props.children}
    </div>
  );
};
