import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      border: "1px solid #6600CB",
      backgroundColor: "#091250",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: "bold",
      color: "#FFF",
    },
    "& .MuiButton-root": {
      color: "#091250",
      backgroundColor: "#58DF46",
    },
  },
}));

export default function MessageDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">
        {props.title ? props.title : ''}
      </DialogTitle>
      { props?.subtitle &&
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#FFF" }}
          >
            { props.subtitle }
          </DialogContentText>
        </DialogContent>
      }
      { props?.component &&
        <DialogContent
          id="alert-dialog-component"
        > { props.component }
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={props.handleClose} >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
