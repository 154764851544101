import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import BannerSaque from '../../images/banner-saque.png';
import LoadingButton from '../../components/LoadingButton'
import { numberAsCurrency } from '../utils/index';

// 
const useStyles = makeStyles((theme) => ({
  newAccount: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    alignSelf: 'start'
  },
  newAccountFooter: {
    marginTop: '20px',
    alignSelf: 'start',
    fontSize: '14px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #6600CB',
    padding: '32px',
    backgroundColor: '#091250',
  },
  sendValueBtn: {
    fontSize: '12px',
    padding: '17px 5px',
    marginTop: '9px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  valueDiv: {
    display: 'block'
  }
}));

export default function Withdrawal({ withdrawalMinimumValue, currentUser }) { // minimumWithdrawal
  const classes = useStyles();
  const [withdrawal, setWithdrawal] = useState({ value: '' });
  const [solicitarLoading, setSolicitarLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [returnMessage, setReturnMessage] = useState();
  
  const valueRef = useRef('');
  const currencyMask = useRef('');
  
  const history = useHistory();

  function submit(e) {
    e.preventDefault();
    
    setErrors({});
    setSolicitarLoading(true);
    axios
      .post('/api/v1/withdrawal_requests', { value: currentUser.balance })
      .then(function (response) {
        console.log(response?.status);
        history.push({
          pathname: '/site/withdrawals',
          state: { dialog: true },
        });
      })
      .catch(function ({ response }) {
        if (response?.status == 422) {
          setErrors({ ...errors, value: response.data?.value?.join(', ') });
        }
      })
      .then(res => {
        setSolicitarLoading(false);
      });
  };

  return (
    <div className={classes.paper}>
      <Typography className={classes.newAccountFooter}>
        <img src={BannerSaque} alt="Banner Saque" style={{width: '100%'}} />
      </Typography>
      <form className={classes.form} onSubmit={submit} noValidate>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required={true}
            key={'value'}
            id={'value'}
            name={'value'}
            label={'Valor'}
            fullWidth={true}
            value={numberAsCurrency(currentUser.balance)}
            error={!!errors.value}
            type={'text'}
            helperText={errors['value'] ? errors['value'] : ''}
            // onBlur={(e) => setValue(e.target.value)}
            // onChange={(e) => setValue(e.target.value)}
            // InputProps={{
            //   inputComponent: TextMaskCurrency,
            // }}
          />
        </div>
        {returnMessage ?
          <div className={classes.valueDiv}>
            <div style={{ textAlign: '-webkit-center', marginTop: '30px' }}>
              <span style={{ color: '#fff', float: 'left' }}>{returnMessage ? "Se este é o seu nome, prossiga:" : ""}</span><br /><br />
              <span style={{ color: '#fff', fontSize: '1.5rem' }}>{returnMessage}</span>
            </div>
          </div>
          :
          ""
        }
        <LoadingButton
          type="submit"
          fullWidth
          loading={solicitarLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          id="submitButton"
        >
          SOLICITAR
        </LoadingButton>
      </form>
    </div>
  );
}
