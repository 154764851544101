import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme';
import HelpIcon from '../../images/question.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    background: 'linear-gradient(90deg, #0F1896 -10.57%, #6600CB 91.15%)',
    borderRadius: '20px',
    color: 'white',
    padding: '10px',
    margin: '30px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: '140%',
    fontWeight: 600,
    '& small': {
      fontWeight: 400,
      fontSize: '70%',
      marginLeft: '10px'
    },
    '& img': {
      marginRight: '10px'
    }
  },

}));

export default function SmallFaq() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Link to="/site/faq" style={{textDecoration: 'none'}}>
        <div className={classes.main}>
          <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/question.svg" />
          Dúvidas?
          <small>Clique aqui</small>
        </div>
      </Link>
    </ThemeProvider>
  )
}